<template>
  <div class="flex justify-center bg-opacity-100 border-0 bg-white w-full min-h-screen">
    <div style="max-width: 1280px" class="p-2">
      <a class="hidden sm:block justify-center" @click="redirectToURL(data.main_banner.link)">
        <img :src="data.main_banner.desktop_image_url" class="max-w-full h-auto object-cover rounded"
          style="max-height: 340px; min-height: 160px;" />
      </a>
      <a class="justify-center flex sm:hidden" @click="redirectToURL(data.main_banner.link)">
        <img :src="data.main_banner.mobile_image_url" class="max-w-full h-auto object-cover"
          style="min-height: 160px;" />
      </a>
      <div style="" class="sm:pt-0">
        <h3 class="title pt-10">Shop Categories</h3>

        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 gap-5 pt-8">
          <div @click="onCategoryClick(i)" v-for="i in data.popular_category" :key="i"
            class="p-2 cursor-pointer transition duration-300 w-full flex flex-col justify-between cat-card hover:bg-primary2 hover:text-white">
            <div class="h-1/6 text-xl 2xl:text-2xl">{{ i.name }}</div>
            <div class="flex h-4/6 justify-end align-bottom w-full">
              <img :src="i.image" :alt="i.name" />
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4" style="margin-top: 41px">
        <a class="hidden md:block" @click="redirectToURL(data.promo_left_url)">

          <img class="w-full object-cover" style="min-height: 110px" :src="data.desktop_promo_left_image"
            alt="promo_1" />
        </a>
        <a class="md:hidden" @click="redirectToURL(data.promo_left_url)">

          <img class="w-full object-cover" style="min-height: 110px" :src="data.mobile_promo_left_image"
            alt="promo_1" />
        </a>
        <a class="hidden md:block" @click="redirectToURL(data.promo_right_url)">
          <img style="min-height: 110px" class="w-full object-cover" :src="data.desktop_promo_right_image"
            alt="promo_1" />
        </a>

        <a class="md:hidden" @click="redirectToURL(data.promo_right_url)">
          <img style="min-height: 110px" class="w-full object-cover" :src="data.mobile_promo_right_image"
            alt="promo_1" />
        </a>
      </div>

      <div>
        <h3 class="title pt-12 pb-4">Popular Products</h3>
        <builder-products :product_ids="data.popular_product" />
      </div>

      <div>
        <h3 class="title pt-10">Popular Brands</h3>
        <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-2">
          <div v-for="i in data.popular_brand" :key="i"
            class="brand-card hover:bg-primary3 cursor-pointer w-full flex items-center justify-center"
            @click="onBrandClick(i)">
            <img class="w-8/12 object-cover" style="max-height: 70px" :src="i.image" alt="brand_image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import builderProducts from "@/views/superAdmin/store/homepage/builderProducts.vue";

// Utils
import {
  trackEcommerceEvent,
  VIEW_ITEM_LIST_EVENT,
  SELECT_ITEM_LIST_EVENT
} from '../../../../utils/gtag'

export default {
  components: {
    builderProducts,
  },
  data() {
    return {
      data: [],
      userType: undefined
    };
  },

  methods: {
    ...mapActions("eCommerce", ["getJsonSettings"]),

    onCategoryClick(data) {
      let routerName = "";

      const userType = JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      ).userType;

      const role = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
      );

      if (this.userType === 'superAdmin') {
        routerName = "super-admin/store"
      } else {
        if (role === "Org Owner") {
          routerName = "org-owner";
        }
        else {
          if (userType === "doctor") {
            routerName = "doctor";
          } else {
            if (userType === "nurse") {
              routerName = "nurse";
            } else {
              routerName = "basic";
            }
          }
        }
      }

      window.location = `/${routerName}/shop/category/${data.slug}?page=1`
    },
    onBrandClick(data) {
      let routerName = "";

      const userType = JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      ).userType;

      const role = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
      );

      if (this.userType === 'superAdmin') {
        routerName = "super-admin/store"
      } else {
        if (role === "Org Owner") {
          routerName = "org-owner";
        }
        else {
          if (userType === "doctor") {
            routerName = "doctor";
          } else {
            if (userType === "nurse") {
              routerName = "nurse";
            } else {
              routerName = "basic";
            }
          }
        }
      }

      window.location = `/${routerName}/shop/brands/${data.slug}?page=1`
    },
    redirectToURL(url) {
      if (typeof window !== "undefined") {
        window.location.href = this.$replaceUrlTemplate(url, this.$getUserType());
      }
    },
  },
  created() {
    this.getJsonSettings({ slug: "homepage" }).then((val) => {
      this.data = val.data.data;
      trackEcommerceEvent(VIEW_ITEM_LIST_EVENT, {
        item_list_id: 'shop_homepage',
        item_list_name: 'Shop Homepage',
        items: this.data.popular_product.map((product) => {
          return {
            item_id: product,
            item_name: product
          };
        }),
      });
    });
    this.userType = this.$store.state.AppActiveUser.userType

  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #074230;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cat-card {
  height: 120px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--white, #fff);
  box-shadow: 0px 4px 8px 0px rgba(116, 116, 116, 0.16);
}

div.cat-card {
  color: #074230;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.brand-card {
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  border: 1px solid #E5E7EB !important;
}

.brand-card:hover img {
  filter: invert(100%);
}

.vs-card--content {
  padding: 0 !important;
}
</style>
